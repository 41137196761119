<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="searchBem"
        readonly
    >
      <u-popover v-if="findedData" hover :hover-delay="500" class="popover-user-profile" anchor="bottom middle"
                 self="top middle" :offset="[10, 10]">
        <div class="wrapper bg-white font-12">
          <div class="row">
            <div class="col m-r">ID do bem: <strong>{{findedData.id}}</strong></div>
            <div class="col bold">Código: <strong>{{findedData.codigo}}</strong></div>
          </div>
          <div class="row m-t">
            <div class="col m-r">Comitente: <strong>{{findedData.comitente && findedData.comitente.pessoa ? findedData.comitente.pessoa.name : findedData.comitente}}</strong></div>
          </div>
          <div class="m-t">
            <div class="m-b">Descrição</div>
            <div><strong>{{findedData.descricao}}</strong></div>
          </div>
          <div class="m-t">
            <e-btn label="Acessar bem" />
          </div>
        </div>
      </u-popover>
    </erp-input>
    <a @click="searchBem"><i class="erp-icon search min"></i> </a>
    <u-icon v-if="findedData" @click.native="bemWindow()" name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" />
    <u-icon @click.native="bemWindow()" name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" />
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import searchBem from '../../../bem/windows/search/SearchBem'
import bem from "@/components/bem/windows/bem"

export default {
  name: 'BemInput',
  mixins: [InputMixin],
  data () {
    return {
      findedData: null,
      description: null
    }
  },
  watch: {
  },
  components: {HelperInputBtn, UPopover},
  methods: {
    setData (d) {
      this.findedData = d
    },
    __updateInternal() {
      this.updateInternal((v) => v.id + ' - ' + v.descricao)
      this.$emit('changeBem', this.findedData)
    },
    searchBem: searchBem,
    bemWindow: bem
  }
}
</script>

<style scoped>

</style>
